import React, { useEffect } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"
import { CookiesProvider } from "react-cookie"
import GclidCookie from 'src/utils/GclidCookie'

const Index = ({ children }) => {

  //https://github.com/tailwindlabs/tailwindcss/discussions/1610#discussioncomment-352836
  //gets screen size - to fix mobile viewport height problem
  useEffect(() => {
    if (typeof window !== 'undefined') {

        function handleResize() {
          let vh = window.innerHeight * 0.01
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
      }
  }, []);

  return(
    <React.Fragment>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/de3b1ee5ca.js"
          crossOrigin="anonymous"
          defer
        ></script>
      </Helmet>
      <Helmet>
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            {(function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/5a9e9d2cd7591465c7084e33/default';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0.parentNode.insertBefore(s1,s0);
            })()}
          `}
        </script>
        <link rel="icon" href="/deuren-logomark.svg" type="image/svg" />
      </Helmet>
      <Header />
      <CookiesProvider>
        <GclidCookie/>
        <main className="mt-[86px] xl:mt-[101px]">{children}</main>
      </CookiesProvider>
      <Footer></Footer>
    </React.Fragment>
  )
}

export default Index
