import React from "react"
import { graphql } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Blocks from "src/components/Blocks"
import BlockCta from "src/components/Blocks/BlockCta"
import BottomStrip from "src/components/BottomStrip"

const Page = ({ location, data: { prismicPage } }) => {
  const { data } = prismicPage

  // Custom parent mess starts here
  // We're doing this because we can't select a custom page as a parent in prismic
  let crumbs = []
  let crumbsFirst = []
  let crumbsSecond = []

  if (data.custom_parent) {
    if (data.custom_parent === "/internal-doors/configurations") {
      crumbsFirst = ["Internal Doors", "internal-doors"]
      crumbsSecond = ["Configurations", "internal-doors/configurations"]
    } else if (data.custom_parent === "/front-doors/configurations") {
      crumbsFirst = ["Front Doors", "front-doors"]
      crumbsSecond = ["Configurations", "front-doors/configurations"]
    } else if (data.custom_parent === "/garage-doors/configurations") {
      crumbsFirst = ["Garage Doors", "garage-doors"]
      crumbsSecond = ["Configurations", "garage-doors/configurations"]
    } else if (data.custom_parent === "/internal-doors/woods-finishes") {
      crumbsFirst = ["Internal Doors", "internal-doors"]
      crumbsSecond = ["Woods & Colours", "internal-doors/woods-finishes"]
    } else if (data.custom_parent === "/front-doors/woods-finishes") {
      crumbsFirst = ["Front Doors", "front-doors"]
      crumbsSecond = ["Woods & Colours", "front-doors/woods-finishes"]
    } else if (data.custom_parent === "/garage-doors/woods-finishes") {
      crumbsFirst = ["Garage Doors", "garage-doors"]
      crumbsSecond = ["Woods & Colours", "garage-doors/woods-finishes"]
    } else if (data.custom_parent === "/request-a-quote") {
      crumbsFirst = ["Request a Quote", "/request-a-quote"]
    } else if (data.custom_parent === "/visit-the-factory") {
      crumbsFirst = ["Visit the workshop", "/visit-the-factory"]
    }

    crumbs = [
      { label: crumbsFirst[0], href: `/${crumbsFirst[1]}` },
      crumbsSecond && { label: crumbsSecond[0], href: `/${crumbsSecond[1]}` },
      {
        label: data.title.text,
        href: `${data.custom_parent}/${
          data.custom_slug ? data.custom_slug : prismicPage.uid
        }`,
        active: true,
      },
    ]
  } else {
    crumbs =
      data.parent.document !== null // if
        ? [
            {
              label: data.parent.document.data.title.text,
              href: `/${
                data.parent.document.data.custom_slug
                  ? data.parent.document.data.custom_slug
                  : data.parent.uid
              }`,
            },
            {
              label: data.title.text,
              href: `/${
                data.parent.document.data.custom_slug
                  ? data.parent.document.data.custom_slug
                  : data.parent.uid
              }/${data.custom_slug ? data.custom_slug : prismicPage.uid}`,
              active: true,
            },
          ] // else
        : [
            {
              label: data.title.text,
              href: `/${data.custom_slug ? data.custom_slug : prismicPage.uid}`,
              active: true,
            },
          ]
  }

  function BreadcrumbsRender(props) {
    if (props.page === "Homepage") {
      return null
    }
    let firstBlock = ""
    if (props.blocks[0]) {
      firstBlock = props.blocks[0].slice_type
    } else {
      firstBlock = "noblocksatall"
      return null
    }

    const theme = firstBlock === "hero" ? "dark" : "light"
    return (
      <Breadcrumbs
        data={crumbs}
        theme={theme}
        className={
          props.blocks[0].slice_type === "hero"
            ? "absolute py-6"
            : "py-5"
        }
      />
    )
  }

  return (
    <>
      <Seo
        title={data.meta_title.text ? data.meta_title.text : data.title.text}
        description={
          data.meta_description.text
            ? data.meta_description.text
            : data.description.text
        }
        image={data.meta_image?.url ? data.meta_image.url : data.featured_image?.url}
        noIndex={data.no_index}
      />
      {data.disable_breadcrumbs ? (
        ""
      ) : (
        <BreadcrumbsRender page={data.title.text} blocks={data.body} />
      )}
      <Blocks blocks={data.body} />
      <BlockCta disabled={data.remove_default_footer_ctas} />
      <BottomStrip />
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        no_index
        disable_breadcrumbs
        custom_slug
        title {
          text
        }
        description {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        parent {
          uid
          document {
            ... on PrismicPage {
              data {
                title {
                  text
                }
                custom_slug
              }
            }
          }
        }
        custom_parent
        remove_default_footer_ctas
        body {
          ... on PrismicPageDataBodyLogoClouds {
						slice_type
            items {
							title1 {
								text
              }
              icon {
								url
              }
              link {
								url
              }
            }
          }        
          ... on PrismicPageDataBodyHero {
            slice_type
            items {
              carousel_images {
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            primary {
              enable_carousel
              background_image {
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              background_video {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyVideo {
            slice_type
            primary {
              video_title {
                text
              }
              video_description {
                text
                html
                richText
              }
              video_asset {
                url
              }
              video_poster_image {
                url
              }
            }
          }
          ... on PrismicPageDataBodyTestimonial {
            slice_type
            primary {
              text {
                text
              }
              position
              background_colour
              remove_bottom_padding
            }
          }
          ... on PrismicPageDataBodyThreeColumns {
            slice_type
            items {
              column_link {
                slug
                uid
                type
                document {
                  ... on PrismicPage {
                    id
                    data {
                      custom_slug
                      custom_parent
                      parent {
                        document {
                          ... on PrismicPage {
                            uid
                            data {
                              custom_slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              column_text {
                text
                html
                richText
              }
              image {
                url
                alt
                fluid(srcSetBreakpoints: [500, 1000]) {
                  ...GatsbyPrismicImageFluid
                }
              }
              video_link {
                text
              }
              title {
                text
              }
              plain_column_link {
                text
              }
              button_text {
                text
              }
              image_is_icon
            }
            primary {
              background_colour
              center_content
              remove_bottom_padding
              alternative_titles_style
            }
          }
          ... on PrismicPageDataBodyFullWidthImage {
            slice_type
            primary {
              remove_bottom_padding
            }
            items {
              image {
                url
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              video_link {
                text
              }
            }
          }
          ... on PrismicPageDataBodyFullWidthContent {
            slice_type
            primary {
              button_link {
                slug
                uid
                type
                document {
                  ... on PrismicPage {
                    id
                    data {
                      custom_slug
                      custom_parent
                      parent {
                        document {
                          ... on PrismicPage {
                            uid
                            data {
                              custom_slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              button_text {
                text
              }
              heading {
                text
              }
              text {
                html
                richText
              }
              plain_button_link {
                text
              }
              remove_bottom_padding
              background_colour
            }
          }
          ... on PrismicPageDataBodyImagesGrid {
            slice_type
            items {
              image {
                url(imgixParams: { auto: "format" })
                fluid {
                  ...GatsbyPrismicImageFluid
                }
                thumbnails {
                  Wide {
                    url(imgixParams: { auto: "format" })
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  Narrow {
                    url(imgixParams: { auto: "format" })
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
                alt
              }
              video_link {
                text
              }
              image_width
            }
            primary {
              background_colour
              remove_bottom_padding
            }
          }
          ... on PrismicPageDataBodyTwoColumnImageAndContent {
            primary {
              button_link {
                uid
                type
                document {
                  ... on PrismicPage {
                    id
                    data {
                      custom_slug
                      custom_parent
                      parent {
                        document {
                          ... on PrismicPage {
                            uid
                            data {
                              custom_slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              button_text {
                text
              }
              content {
                html
                richText
              }
              heading {
                text
              }
              image {
                alt
                url
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              video_link {
                text
              }
              plain_button_link {
                text
              }
              remove_bottom_padding
              reverse_columns
              background_colour
            }
            slice_type
          }
          ... on PrismicPageDataBodyContactForm {
            slice_type
            primary {
              form_background_image {
                url
              }
            }
          }
          ... on PrismicPageDataBodyStickyContentAndImages {
            slice_type
            primary {
              background_colour
              content {
                richText
              }
              heading {
                text
              }
              remove_bottom_padding
              reverse_columns
              button_link {
                text
              }
              button_text {
                text
              }
            }
            items {
              image {
                url
                alt
                fluid(maxWidth: 200) {
                  src
                }
              }
              video_link {
                text
              }
            }
          }
          ... on PrismicPageDataBodyAccordion {
            slice_type
            primary {
              accordion {
                uid
                document {
                  ... on PrismicAccordion {
                    data {
                      title {
                        text
                      }
                      description {
                        richText
                      }
                      button_link {
                        text
                      }
                      accordion {
                        accordion_title {
                          text
                        }
                        accordion_description {
                          richText
                        }
                        featured_image {
                          url
                          alt
                          fluid(maxWidth: 500) {
                            ...GatsbyPrismicImageFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyWoodsAndColours {
            slice_type
            primary {
              type
            }
          }
          ... on PrismicPageDataBodyFrameAnimation {
            slice_type
            primary {
              frame_animation_title {
                text
              }
              frame_animation_description {
                html
              }
            }
          }
          ... on PrismicPageDataBodyCenterSlider {
            slice_type
            primary {
              slider {
                document {
                  ... on PrismicCenterSlider {
                    data {
                      remove_bottom_padding
                      slides_background_colour
                      slides {
                        button_link {
                          text
                        }
                        content {
                          richText
                        }
                        image {
                          url
                          alt
                        }
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
              pull_from_type
            }
          }
          ... on PrismicPageDataBodyCaseStudies {
            slice_type
            primary {
              functionality
            }
            items {
              case_study {
                document {
                  ... on PrismicCaseStudy {
                    uid
                    data {
                      title {
                        text
                      }
                      featured_image {
                        url
                        alt
                        fluid(srcSetBreakpoints: [400, 800]) {
                          srcSet
                        }
                      }
                      description {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyFeaturePoints {
            id
            slice_type
            primary {
              title {
                text
              }
              content {
                richText
              }
              base_image {
                url
                alt
              }
            }
            items {
              top_position
              left_position
              image {
                url
                alt
              }
              content {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyVisitTheFactoryForm {
            slice_type
            primary {
              form_background_image {
                url
                alt
              }
            }
          }
          ... on PrismicPageDataBodyDeurenMap {
            slice_type
            primary {
              hide_map
            }
          }
          ... on PrismicPageDataBodyRepeatableContent {
            slice_type
            items {
              title {
                text
              }
              content {
                richText
              }
              put_cookies_table_here
            }
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyBlogs {
            slice_type
            primary {
              functionality
            }
            items {
              blog_post {
                document {
                  ... on PrismicBlogPost {
                    uid
                    data {
                      title {
                        text
                      }
                      description {
                        richText
                      }
                      featured_image {
                        alt
                        url
                        fluid {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      post_date(formatString: "DD MMM YYYY")
                    }
                    tags
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTotallyBespoke {
            slice_type
          }
        }
      }
    }
  }
`
